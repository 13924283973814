import React, { useState, useEffect } from "react";

import moment from "moment";

import resultAPI, { PendingResult } from "~/utils/api/v2/results";

interface ParsedResult {
  id: string;
  created_at: string;
  indicator: string;
  old_value: string;
  value: string;
  measurement: string;
  min_range: any;
  max_range: any;
  category: any;
  critical: boolean;
  telehealth_mail_sent: boolean;
  biomarker: string;
  result: string;
  service: any;
  item: any;
}

interface Props {
  id: string;
}

const Form: React.FC<Props> = ({ id }: Props) => {
  const [parsedResults, setParsedResults] = useState<ParsedResult[]>([]);
  const [result, setResult] = useState<PendingResult | null>(null);
  const [formData, setFormData] = useState<Record<string, number>>({});
  const [selectedDate, setSelectedDate] = useState<string>("");
  const token = typeof window !== "undefined" ? localStorage.getItem("token") : null;

  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const resultResponse = await resultAPI.getResult(id, token);
          setResult(resultResponse.data);

          const parsedResultsResponse = await resultAPI.getParsedResults(id, token);
          setParsedResults(parsedResultsResponse.data);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    };

    fetchData();
  }, [id, token]);

  const handleDownload = (fileLink: string) => {
    window.open(fileLink, "_blank");
  };

  return (
    <div className="bg-white p-5">
      {result && (
        <button
          onClick={() => handleDownload(result.file_link)}
          className="mb-5 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 3a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v3h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2V3zm3 5V5a1 1 0 1 1 2 0v3h2a1 1 0 1 1 0 2H8a1 1 0 0 1-1-1zm2 6a1 1 0 0 1 0-2h2a1 1 0 1 1 0 2H8z"
              clipRule="evenodd"
            />
          </svg>
          Ver Resultado
        </button>
      )}
      <div className="flex flex-col mb-4">
        <div className="mb-3 flex flex-col">
          <span>Paciente</span>
          <span>
            {result?.patient.fullname} {result?.patient.document_number}
          </span>
        </div>

        <label
          htmlFor="date"
          className="mb-1 text-gray-700"
        >
          Fecha de toma de exámenes:
        </label>
        {moment(result?.created_at).format("DD-MM-YYYY")}
      </div>
      <div className="flex flex-col">
        {parsedResults.map((item) => (
          <div
            key={item.id}
            className="flex"
          >
            <span className="mr-3">
              {item.indicator}
              {": "}
            </span>{" "}
            <span>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Form;
